export const WEALTHIEST_DATA = [
    {
        name: "Elon Musk",
        net_worth: "$258.8 billion",
        age: 53,
        country: "United States",
        companies: ["Tesla", "SpaceX"],
        profile: "/wealthiest/elon_musk.jpeg"
    },
    {
        name: "Larry Ellison",
        net_worth: "$206.7 billion",
        age: 79,
        country: "United States",
        company: "Oracle",
        profile: "/wealthiest/larry_ellison.png"
    },
    {
        name: "Mark Zuckerberg",
        net_worth: "$202.2 billion",
        age: 40,
        country: "United States",
        company: "Meta"
    },
    {
        name: "Jeff Bezos",
        net_worth: "$199.1 billion",
        age: 60,
        country: "United States",
        company: "Amazon"
    },
    {
        name: "Bernard Arnault & family",
        net_worth: "$189.4 billion",
        age: 74,
        country: "France",
        company: "LVMH"
    },
    {
        name: "Warren Buffett",
        net_worth: "$142.6 billion",
        age: 93,
        country: "United States",
        company: "Berkshire Hathaway"
    },
    {
        name: "Larry Page",
        net_worth: "$135.7 billion",
        age: 51,
        country: "United States",
        company: "Google"
    },
    {
        name: "Sergey Brin",
        net_worth: "$129.9 billion",
        age: 50,
        country: "United States",
        company: "Google"
    },
    {
        name: "Amancio Ortega",
        net_worth: "$129.1 billion",
        age: 88,
        country: "Spain",
        company: "Zara"
    },
    {
        name: "Steve Ballmer",
        net_worth: "$120.8 billion",
        age: 67,
        country: "United States",
        companies: ["Microsoft", "Los Angeles Clippers"]
    },
    {
        name: "Jensen Huang",
        net_worth: "$111.6 billion",
        age: 61,
        country: "United States",
        company: "Nvidia"
    },
    {
        name: "Mukesh Ambani",
        net_worth: "$108.9 billion",
        age: 66,
        country: "India",
        company: "Reliance Industries"
    },
    {
        name: "Bill Gates",
        net_worth: "$105.1 billion",
        age: 68,
        country: "United States",
        companies: ["Microsoft", "Bill & Melinda Gates Foundation"]
    },
    {
        name: "Michael Dell",
        net_worth: "$105.1 billion",
        age: 59,
        country: "United States",
        company: "Dell Technologies"
    },
    {
        name: "Michael Bloomberg",
        net_worth: "$104.7 billion",
        age: 82,
        country: "United States",
        company: "Bloomberg LP"
    },
    {
        name: "Rob Walton & family",
        net_worth: "$98.3 billion",
        age: 79,
        country: "United States",
        company: "Walmart"
    },
    {
        name: "Jim Walton & family",
        net_worth: "$97.1 billion",
        age: 76,
        country: "United States",
        company: "Walmart"
    },
    {
        name: "Alice Walton",
        net_worth: "$89.7 billion",
        age: 74,
        country: "United States",
        company: "Walmart"
    },
    {
        name: "Francoise Bettencourt Meyers & family",
        net_worth: "$88.5 billion",
        age: 70,
        country: "France",
        company: "L'Oréal"
    },
    {
        name: "Carlos Slim Helu & family",
        net_worth: "$88.0 billion",
        age: 84,
        country: "Mexico",
        companies: ["América Móvil", "Grupo Carso"]
    }
]
